<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="imports">
      <heade></heade>
      <div class="con">
          <div class="tit">
              <div><span @click="dinaji_fanhui">返回</span>{{tit_name}}</div>
              <div @click="dianji_rzhi">操作日志<i></i></div>
          </div>
          <div class="box">
              <ul>
                  <li>
                      <span>1</span>{{con_text1}}<p @click="moban_xiazai">下载</p>
                  </li>
                  <li style="margin-top:0.42rem;">
                      <span>2</span>{{con_text2}}
                      <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=''
                        :file-list="fileList"
                        :multiple="false"
                        :auto-upload="false">
                        <el-button style="background: #9a86db;border:none;margin-left:0.15rem;margin-right:0.3rem;" slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                  </li>
              </ul>
              <p class="jianyi">单次导入条数不超过10000条</p>
              <p class="p1">{{foot_text1}}</p>
              <div class="shangchuan" @click="uploadFile">上传</div>
          </div>
      </div>
      <foote></foote>
      <jindutiao ref="jindu_tiao"></jindutiao>
    </div>
</template>

<script>
import $ from 'jquery'
import heade from '../components/Heade'
import foote from '../components/Foote'
import axios from '../api/ajax'
import jindutiao from '../components/jindutiao/jindutiao'
export default {
  name: 'imports',
  data () {
    return {
      fileList: [],
      ent_id: '',
      tit_name: '',
      piliangdaoru: '',
      con_text1: '',
      con_text2: '',
      foot_text1: ''
    }
  },
  components: { heade, foote, jindutiao },
  created () {
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
    if (sessionStorage.getItem('pc_mlbb_piliangdaoru') != null && sessionStorage.getItem('pc_mlbb_piliangdaoru') != undefined && sessionStorage.getItem('pc_mlbb_piliangdaoru') != '') {
      this.piliangdaoru = sessionStorage.getItem('pc_mlbb_piliangdaoru')
      if (this.piliangdaoru == '部门') {
        this.tit_name = '批量导入部门和人员'
        this.con_text1 = '下载添加部门员工名单模板，批量添加部门和员工'
        this.con_text2 = '上传填写好的员工信息表'
        this.foot_text1 = '法律声明：企业员工和部门导入服务由CBR研发并经授权企业管理员选择开通、使用、管理，相关数据存储于CBR专属云存储空间并归属企业所有。CBR安全技术已通过相关机构认证，请放心使用。'
      } else if (this.piliangdaoru == '科目') {
        this.tit_name = '批量导入核算科目'
        this.con_text1 = '下载添加核算科目，批量添加核算科目'
        this.con_text2 = '上传填写好的核算科目'
        this.foot_text1 = '法律声明：企业核算科目导入服务由CBR研发并经授权企业管理员选择开通、使用、管理，相关数据存储于CBR专属云存储空间并归属企业所有。CBR安全技术已通过相关机构认证，请放心使用。'
      } else if (this.piliangdaoru == '产品') {
        this.tit_name = '批量导入产品'
        this.con_text1 = '下载添加产品，批量添加产品'
        this.con_text2 = '上传填写好的产品'
        this.foot_text1 = '法律声明：企业产品导入服务由CBR研发并经授权企业管理员选择开通、使用、管理，相关数据存储于CBR专属云存储空间并归属企业所有。CBR安全技术已通过相关机构认证，请放心使用。'
      }
    }
  },
  methods: {
    //   下载多个文件
    multiDownLoad (val) {
      for (let i = 0; i < val.length; i++) {	
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.style.height = 0
        iframe.src = val[i] 
        document.body.appendChild(iframe)
        setTimeout(res => {
          iframe.remove()
        }, 5 * 60 * 1000
        )
      }  
    },
    // 模板下载
    moban_xiazai () {
      if (this.piliangdaoru == '部门') {
        const data = ['https://www.mlcbr.com/template/CBR-员工导入模板.xlsx']
        this.multiDownLoad(data)
      } else if (this.piliangdaoru == '科目') {
        const data = ['https://www.mlcbr.com/template/核算科目模板.xlsx']
        this.multiDownLoad(data)
      } else if (this.piliangdaoru == '产品') {
        const data = ['https://www.mlcbr.com/template/CBR-产品导入模板.xlsx']
        this.multiDownLoad(data)
      }
    },
    // 上传文件
    uploadFile () {
      console.log(this.$refs.upload.uploadFiles)
      const date = this.$refs.upload.uploadFiles
      const list = []
      const shibai = []
      const fasong = []
      for (let i = 0; i < date.length; i++) {
        list.push(date[i].raw)
      }
      for (let i = 0; i < list.length; i++) {
        if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
          shibai.push(list[i])
        } else {
          const fileObj = list[i]
          const form = new FormData()// FormData 对象
          form.append('files', fileObj)// 文件对象  'upload'是后台接收的参数名
          form.append('ent_id', this.ent_id)
          console.log(fileObj)
          sessionStorage.setItem('pc_mlbb_shangchuan_chenggong', fileObj.name)
          if (this.piliangdaoru == '部门') {
            axios({
              method: 'post',
              url: '/import_staff_ai',
              type: 'post',
              data: form,
              onUploadProgress: progressEvent => {
                var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
                // this.text = complete + '%'
                console.log(complete + '%')
                // this.$refs.jindu_tiao.huoqu(true,complete)
              }
            }).then(res => {
              console.log(res)
              // this.$refs.jindu_tiao.huoqu(false,0)
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.data.body))
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju2', '部门')
              if (res.data.code == 10143) {
                this.$message({
                  message: '导入成功',
                  type: 'success'
                })
                this.$router.push('/feedbackpage')
              } else if (res.data.code == 10144) {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                }) 
              } else if (res.data.code == 11800) {
                this.$message({
                  message: '导入文件的sheet页名不对',
                  type: 'error'
                })
              } else if (res.data.code == 11900) {
                this.$message({
                  message: '导入文件的模板格式不对',
                  type: 'error'
                })
              }
            })
            // $.ajax({
            //     url:'https://www.mlcbr.com/hwb_client/V1.0.0/import_staff_ai',//服务器
            //     // url:'http://192.168.3.5:8080/hwb_client/V1.0.0/import_staff_ai',
            //     type: 'post',
            //     data: form,
            //     processData: false,
            //     contentType: false,	
            // }).then(res=>{
            //     console.log(res)
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju", JSON.stringify(res.body));
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju2", '部门');
            //     if(res.code==10143){
            //         this.$message({
            //             message: '导入成功',
            //             type: 'success'
            //         });
            //         this.$router.push('/feedbackpage')
            //     }else if(res.code==10144){
            //         this.$message({
            //             message: '导入失败，请重新导入',
            //             type: 'error'
            //         }); 
            //     }
            // })
          } else if (this.piliangdaoru == '科目') {
            axios({
              method: 'post',
              url: '/import_subject_ai',
              type: 'post',
              data: form,
              onUploadProgress: progressEvent => {
                var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
                // this.text = complete + '%'
                console.log(complete + '%')
                // this.$refs.jindu_tiao.huoqu(true,complete)
              }
            }).then(res => {
              console.log(res)
              // this.$refs.jindu_tiao.huoqu(false,0)
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.data.body))
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju2', '科目')
              if (res.data.code == 10175) {
                this.$message({
                  message: '导入成功',
                  type: 'success'
                })
                this.$router.push('/feedbackpage')
              } else if (res.data.code == 10176) {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                }) 
              }
            })
            // $.ajax({
            //     url:'https://www.mlcbr.com/hwb_client/V1.0.0/import_subject_ai',//服务器
            //     // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_subject_ai',//测试服务器
            //     type: 'post',
            //     data: form,
            //     processData: false,
            //     contentType: false,	
            // }).then(res=>{
            //     console.log(res)
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju",  JSON.stringify(res.body));
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju2", '科目');
            //     if(res.code==10175){
            //         this.$message({
            //             message: '导入成功',
            //             type: 'success'
            //         });
            //         this.$router.push('/feedbackpage')
            //     }else if(res.code==10176){
            //         this.$message({
            //             message: '导入失败，请重新导入',
            //             type: 'error'
            //         }); 
            //     }
            // })
          } else if (this.piliangdaoru == '产品') {
            axios({
              method: 'post',
              url: '/import_prod_ai',
              type: 'post',
              data: form,
              onUploadProgress: progressEvent => {
                var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
                // this.text = complete + '%'
                console.log(complete + '%')
                // this.$refs.jindu_tiao.huoqu(true,complete)
              }
            }).then(res => {
              console.log(res)
              // this.$refs.jindu_tiao.huoqu(false,0)
              // setTimeout(() => {
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.data.body))
              sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju2', '产品')
              if (res.data.code == 10133) {
                this.$message({
                  message: '导入成功',
                  type: 'success'
                })
                console.log(res)
                this.$router.push('/feedbackpage')
              } else if (res.data.code == 10134) {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                }) 
              } else if (res.data.code == 11800) {
                this.$message({
                  message: '导入文件的sheet页名不对',
                  type: 'error'
                })
              } else if (res.data.code == 11900) {
                this.$message({
                  message: '导入文件的模板格式不对',
                  type: 'error'
                })
              }
              // }, 1000)
            })
            // $.ajax({
            //     url:'https://www.mlcbr.com/hwb_client/V1.0.0/import_prod_ai',//服务器
            //     // url:'http://192.168.3.5:8080/hwb_client/V1.0.0/import_prod_ai',//cs
            //     type: 'post',
            //     data: form,
            //     processData: false,
            //     contentType: false,	
            // }).then(res=>{
            //     console.log(res)
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju", JSON.stringify(res.body));
            //     sessionStorage.setItem("pc_mlbb_shangchuan_chenggong_shuju2", '产品');
            //     if(res.code==10133){
            //         this.$message({
            //             message: '导入成功',
            //             type: 'success'
            //         });
            //         this.$router.push('/feedbackpage')
            //     }else if(res.code==10134){
            //         this.$message({
            //             message: '导入失败，请重新导入',
            //             type: 'error'
            //         }); 
            //     }
            // })
          }
        }
      }
    },
    // 点击日志
    dianji_rzhi () {
      const date = {
        name: '',
        cuowu: false
      }
      if (this.piliangdaoru == '部门') {
        date.name = '部门'
      } else if (this.piliangdaoru == '科目') {
        date.name = '科目'
      } else if (this.piliangdaoru == '产品') {
        date.name = '产品'
      }
      sessionStorage.setItem('pc_mlbb_bumenrzhi', JSON.stringify(date))
      this.$router.push('/calendara')
    },
    dinaji_fanhui () {
      window.history.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.imports {
    background:#E7E8EB;
    min-height: 100vh;
    .con{
        width: 12rem;
        background:#E7E8EB;
        padding-top:2.3rem;
        margin-bottom: 2.78rem;
        margin: 0 auto;
        min-height: 5.5rem;
        .tit{
            height:0.8rem;
            background:#f3f3f3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.54rem;
            // p{
            //     font-size: 0.18rem;
            //     color:#1A2533;
            // }
            div{
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:nth-child(1){
                    font-size: 0.18rem;
                    color:#1A2533;
                    span{
                        padding: 0.05rem 0.1rem;
                        border:1px solid #ccc;
                        border-radius: 0.03rem;
                        background: #fff;
                        margin-right: 0.2rem;
                        cursor: pointer;
                    }
                }
                &:nth-child(2){
                    display: flex;
                    align-items: center;
                    font-size: 0.14rem;
                    color:#979AA1;
                    cursor:pointer;
                    i{
                        display: block;
                        height:0.25rem;
                        width:0.28rem;
                        background: url('../assets/rzhi_no.png') no-repeat;
                        background-position:center center;
                        margin-left: 0.1rem;
                    }
                    &:hover i{
                        background: url('../assets/rzhi_ok.png') no-repeat;
                        background-position:center center;
                    }
                }
            }
        }
        .box{
            width:12rem;
            margin:0 auto;
            background: #fff;
            min-height: 4.7rem;
            margin-bottom: 0.45rem;
            ul{
                padding: 0.95rem 0.6rem 0.46rem 0.6rem;
                li{
                    display: flex;
                    align-items: center;
                    font-size: 0.14rem;
                    color:#4C4A4D;
                    span{
                        display: block;
                        height:0.35rem;
                        width:0.35rem;
                        margin-right: 0.18rem;
                        border:0.01rem solid #979797;
                        font-size: 0.2rem;
                        color:#888888;
                        text-align: center;
                        line-height: 0.35rem;
                        border-radius: 50%;
                    }
                    p{
                        font-size: 0.14rem;
                        color:#fff;
                        padding: 0.05rem 0.12rem;
                        background: #9a86db;
                        margin-left: 0.15rem;
                        border-radius: 0.02rem;
                        cursor:pointer;
                    }
                    a{
                        font-size: 0.14rem;
                        color:#fff;
                        padding: 0.05rem 0.12rem;
                        background: #9a86db;
                        margin-left: 0.15rem;
                        border-radius: 0.02rem;
                        cursor:pointer; 
                    }
                    .upload-demo{
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .jianyi{
                padding: 0 0.6rem;
                color: red;
            }
            .p1{
                font-size: 0.12rem;
                color:#A9A9A9;
                padding: 0 0.6rem;
            }
            .shangchuan{
                width:1.54rem;
                height:0.38rem;
                border-radius: 0.02rem;
                border:0.01rem solid #888888;
                line-height: 0.38rem;
                text-align: center;
                font-size: 0.16rem;
                font-weight: 500;
                color:#888888;
                margin: 0 auto;
                margin-top: 0.61rem;
                cursor:pointer;
            }
        }
    }
    @media screen and (max-width: 12rem) {
      .con {
        width: 12rem;
      }
    }
}
</style>
